'use strict';

const content = {

    contact:
       `<p>Hello! I use web technologies to build things. You could call me a front–end web developer with some back-end, server and design experience.</p>
        <p>If you’d like to get in touch you can <a id="flummox" onclick="">email me</a> or connect on <a href="https://au.linkedin.com/in/sean-franklin" rel="noopener" target="_blank">LinkedIn</a>. Thanks for stopping by!</p>
        <h3>Sean Franklin</h3>`,

    approach:
       `<p>approach</p>`,

    projects:
       `<p>I work on both commercial and personal projects, which range from simple static sites to single-page progressive web apps.</p>
        <p>Please choose a project below for more details, or check out my <a href="https://gitlab.com/u/seanfranklin" rel="noopener" target="_blank">source code</a>.</p>`,

    about:
       `<p>about</p>`,

    'front-end':
       `<p>front end</p>`,

    'back-end':
        '<p>back end</p>',

    tooling:
        '<p>tooling</p>',

    'word!':
        `<div id="word-image" class="project-image" title="Click to open Word!" onclick="window.open('https://go.word-play.com')"></div>
        <div class="blurb right">
            <h3>Word!</h3>
            <p>Multiplayer online word game where the aim is to find the highest scoring word given a set of letters.</p>
        </div>`,

    street_art:
        `<div id="street-art-image" class="project-image" title="Click to open Melbourne Street Art" onclick="window.open('https://melbournestreetart.photos')"></div>
        <div class="blurb right">
            <h3>Melb Street Art</h3>
            <p>Photos of street art I took in Melbourne. Photos can be filtered by content. The site includes an interactive map.</p>
        </div>`,

    policompass:
        `<div id="policompass-image" class="project-image" title="Click to open PoliCompass" onclick="window.open('https://policompass.com')"></div>
        <div class="blurb right">
            <h3>PoliCompass</h3>
            <p>An interactive chart that shows the political alignments of major people and parties. Users can also add their own points.</p>
        </div>`,

    slickpics:
       `<div id="slickpics-image" class="project-image" title="Click to open SlickPics" onclick="window.open('https://slick.pics')"></div>
        <div class="blurb right">
            <h3>SlickPics</h3>
            <p>Configurable online screensaver that displays random images from Flickr, 500px or the local device.</p>
        </div>`,

    storytime:
       `<div id="storytime-image" class="project-image" title="Click to open the StoryTime app in a popup window" onclick="popupStoryTime()"></div>
        <div class="blurb right">
            <h3>StoryTime</h3>
            <ul>
                <li>Single–page app</li>
                <li>Fully responsive</li>
                <li>6000 lines of JS</li>
                <li>Lazy–loads assets</li>
                <li>Sync function uses node.js on back–end</li>
                <li>Android app version uses Apache Cordova</li>
                <li>Build processes use Grunt & sh scripts</li>
            </ul>
        </div>`,

    avonbridge:
       `<div id="avonbridge-image" class="project-image" title="Click to view screenshots" onclick="showAvonbridge()">loading...</div>
        <div class="blurb right">
            <h3>Avonbridge Pensions</h3>
            <p>Info site that included a pension (CETV) calculator. The business no longer operates, but I have some <span class="link" onclick="showAvonbridge()">screenshots</span>.</p>
        </div>`,

    more:
       `<h3><a href="https://slick.pics" rel="noopener" target="_blank">SlickPics</a></h3>
        <p>Online screensaver that displays images from Flickr, 500px or the local device.</p>

        <h3><a href="javascript:void(0)" onclick="popupApp('StoryTime', 'https://storytime.happyforever.com')">StoryTime</a></h3>
        <p>Interactive stories inspired by the <em>Choose Your Own Adventure</em> series.</p>

        <h3><a href="https://senseoftime.info" rel="noopener" target="_blank">Sense of Time</a></h3>
        <p>Interactive timeline that shows geologic eras and evolutionary events.</p>

        <h3><a href="https://quote.seanf.net" rel="noopener" target="_blank">Quote Machine</a></h3>
        <p>Presents random quotes from a chosen person, literary work or theme.</p>

        <h3><a href="https://hotonhn.com" rel="noopener" target="_blank">Hot on HN</a></h3>
        <p>Lists stories popular on Hacker News. It includes an RSS feed.</p>

        <h3><a href="javascript:void(0)" onclick="showAvonbridge()">Avonbridge</a></h3>
        <p>This business longer operates so I’ve linked to screenshots.</p>

        <h3><a href="https://architek.sweetbytes.net" rel="noopener" target="_blank">Architek</a></h3>
        <p>Wordpress site template intended for publishing blog posts.</p>

        <h3><a href="https://clock.seanf.net" rel="noopener" target="_blank">Spiral Clock</a></h3>
        <p>Quirky analog clock with an alarm function and multiple backgrounds.</p>

        <h3><a href="https://pork.seanf.net" rel="noopener" target="_blank">Pork Powder</a></h3>
        <p>Responsive product page that implements modals and a shopping cart.</p>

        <h3><a href="javascript:void(0)" onclick="popupApp('Fractured Quiz', 'https://quiz.fracturedworlds.net')">Fractured Quiz</a></h3>
        <p>Number quiz that demonstrates diagonal sliding divs.</p>

        <h3><a href="https://janedoe.cv32.com" rel="noopener" target="_blank">Jane Doe</a></h3>
        <p>Single–page, responsive CV template with a soft, minimalist theme.</p>

        <h3><a href="https://gitlab.com/hyperdunc/oldspeak/tree/master" rel="noopener" target="_blank">Oldspeak</a></h3>
        <p>Chrome extension that replaces euphemisms with clear language.</p>

        <h3><a href="https://seanf.net/systat/example.html" rel="noopener" target="_blank">SyStat</a></h3>
        <p>Intended for monitoring the status of a UNIX-based remote server.</p>`,

    science:
       `<p>science</p>`,

    language:
       `<p>language</p>`,

    psychology:
       `<p>psychology</p>`,

    wilderness:
       `<p>wilderness</p>`
};
