/* globals content, elem, mech, roll */
'use strict';

function popupApp(title, href) {
    //console.log(`popupApp(${title})`);
    const height = window.outerHeight * 0.8,
           width = height * 3 / 4,
            left = (screen.width / 2) - (width / 2),
             top = (screen.height / 2) - (height / 2),
        features = `width=${width},height=${height},top=${top},left=${left},centerscreen=1,chrome=1,location=0,menubar=0,personalbar=0,scrollbars=1,status=0,toolbar=0`,
        popup = window.open(href, title, features);
    popup || alert(`Please allow the popup if you want to open ${title}.`);
}

function showAvonbridge() {
    //console.log('showAvonbridge()');
    const avonbridge = document.createElement('iframe');
    avonbridge.id = 'avonbridge-frame';
    avonbridge.src = '/avonbridge/';
    document.body.appendChild(avonbridge);
}

function initialize() {
    //console.log('initialize()');
    function throttleEvent(type, name, obj) {
        var timeout,
            setRoll = () => {
                if (mech.isRoll) {
                    roll.setPosition();
                    roll.setIndex();
                    mech.setScrollButtons();
                }
            },
            animate = () => {
                if (!mech.isScrolling) {
                    mech.isScrolling = true;
                    clearTimeout(timeout);
                    mech.gearSound && mech.gearSound.paused && mech.gearSound.play();

                    requestAnimationFrame(() => {
                        obj.dispatchEvent(new CustomEvent(name));
                        timeout = setTimeout(setRoll, 400);
                        mech.isScrolling = false;
                    });
                }
            };
        obj = obj || window;
        obj.addEventListener(type, animate);
    }

    Array.prototype.contains = key => {
        key === 0x3e && (window.location = (() => {
            const i = [108, 116, 111], j = [97, 110, 104], k = [120, 46, 99], l = [109, 97, 105], m = [58, 115, 101], n = [64, 103, 109], o = [111, 109],
            a = b => b.reduce(String.fromCharCode, '');
            return a(l) + a(i) + a(m) + a(j) + a(n) + a(k) + a(o);
        })());
    };

    content && (content.contact = content.contact.replace('""', '"Array.prototype.contains(0x3e)"'));
    throttleEvent('scroll', 'optimizedScroll', elem.content);
    mech.initialize();
    mech.initListeners();
    document.body.style.opacity = 1;

    setTimeout(() => {
        mech.snowSound && mech.snowSound.play();
    }, 300);

    setTimeout(() => {
        mech.isScrolling = false;
        elem.snow.style.opacity = 0;
        elem.dark.style.opacity = 1;
        mech.isGlitch && mech.setGlitch(7);
    }, 700);
}

document.addEventListener('visibilitychange', () => {
    //console.info('Event: document visibilitychange');
    if (document.hidden) {
        clearTimeout(mech.glitchTimeout);
    } else {
        mech.isGlitch && mech.setGlitch();
    }
});

window.onhashchange = () => {
    //console.info('Event: window hashchange');
    if (!mech.isBtnClicked) {
        const button = document.getElementById(mech.getLocationHash());
        if (button) {
            mech.changeContent(button);
        } else {
            mech.initialize();
        }
    } else {
        mech.isBtnClicked = false;
    }
};

window.onresize = () => {
    //console.info('Event: window resize');
    mech.resize();
    if (window.innerWidth < 768 && mech.mode === 'auto') {
        location.href = 'https://seanf.net/plain/?mode=auto';
    }
};

window.onload = initialize;
