/* globals elem */
'use strict';

const roll = {

    position: 0,
    index:    0,
    offset:   0,
    div:      null,
    headings: [],


    setPosition() {
        //console.log('roll.setPosition()');
        roll.position = elem.content.scrollTop;
    },


    setIndex() {
        //console.log('roll.setIndex()');
        if (roll.headings.length) {
            roll.index = (() => {
                for (let i = 0; i < roll.headings.length; i++) {
                    // TODO: More effectively detect if heading is visible
                    if (roll.headings[i].offsetTop - roll.offset >= roll.position) {
                        return i;
                    }
                }
            })();
        }
    },


    scrollUp(index = roll.index) {
        //console.log('roll.scrollUp()');
        const headingOffset = roll.headings[index].offsetTop - roll.offset,
              isTop = (roll.position <= headingOffset);
            
        roll.position = isTop ? headingOffset : roll.position - 6;

        setTimeout(() => {
            elem.content.scrollTop = roll.position;
            !isTop && roll.scrollUp(index);
        }, 16);
    },


    scrollDown(index = roll.index) {
        //console.log('roll.scrollDown()');
        const headingOffset = roll.headings[index].offsetTop - roll.offset,
              isBottom = (roll.position >= headingOffset);

        roll.position = isBottom ? headingOffset : roll.position + 6;

        setTimeout(() => {
            elem.content.scrollTop = roll.position;
            !isBottom && roll.scrollDown(index);
        }, 16);
    },


    initialize() {
        //console.log('roll.initialize()');
        roll.index = 0;
        roll.position = 0;
        roll.div = document.getElementById('roll');
        roll.headings = roll.div ? roll.div.querySelectorAll('h3') : [];
        roll.offset = (roll.headings && roll.headings.length) ? roll.headings[0].offsetTop : 0;
    }

};
