'use strict';

const elem = {

    wrapper:    document.getElementById('mech-wrapper'),
    content:    document.getElementById('content'),
    snow:       document.getElementById('snow'),
    dark:       document.getElementById('dark'),

    key:        document.getElementById('key'),
    elec:       document.getElementById('elec'),
    glitch:     document.getElementById('glitch'),

    gears: {
        top:    document.getElementById('gear-top'),
        left:   document.getElementById('gear-left'),
        bottom: document.getElementById('gear-bottom')
    },

    buttons: {
        top:    document.querySelectorAll('button.top'),
        bottom: document.querySelectorAll('button.bottom'),
        left:   document.querySelector('button.left'),
        right:  document.querySelector('button.right'),
        last:   document.querySelector('button.top.first')
    }

};
