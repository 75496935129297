/* globals content, elem, roll */
'use strict';

// Note: This project has outgrown its original scope. Adding location hash support has
// exposed the brittleness of the mech.changeContent() method, which really needs to be
// broken up and refactored. The only thing that doesn’t currently work is animating the
// content change when the typed hash doesn’t match the ID of a button on the page.

const mech = {

    mode:           '',
    glitchTimeout:  null,

    gearSound:      null,
    snowSound:      null,
    darkSound:      null,

    isBtnClicked:   true,
    isGlitch:       true,
    isGlowRing:     false,
    isRoll:         false,
    isScrolling:    true,

    originalWidth:  1191,
    originalHeight: 1003,

    rollingContent: ['more'],

    buttonLabels: {
        approach:   ['Front-end', 'Back-end', 'Tooling'],
        projects:   ['Word!', 'Street Art', 'PoliCompass', 'more'],
        about:      ['Science', 'Language', 'Psychology', 'Wilderness']
    },

    scrollbarWidth: (function () {
        const div = document.createElement('div');
        div.style.overflowY = 'scroll';
        //div.style.visibility = 'hidden';
        div.style.position = 'absolute';
        div.style.top = '-200px';
        div.style.left = '-200px';
        div.style.width = '100px';
        div.style.height = '100px';
        div.innerHTML = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
        document.body.appendChild(div);
        const scrollbarWidth = div.offsetWidth - div.clientWidth;
        document.body.removeChild(div);
        return scrollbarWidth;
    })(),


    loadSound(sound) {
        //console.log('mech.loadSound()');
        const audioTest = new Audio();
        let audio;
        if (audioTest.canPlayType('audio/ogg')) {
            audio = new Audio(`sounds/${sound}.ogg`);
        } else if (audioTest.canPlayType('audio/mpeg')) {
            audio = new Audio(`sounds/${sound}.mp3`);
        }
        return audio;
    },


    getRandomInteger(min = 0, max = 9) {
        //console.log(`mech.getRandomInteger(${min}, ${max})`);
        return Math.floor(Math.random() * (max - min + 1) + min);
    },


    setGlitch(bias = 5) {
        //console.log(`mech.setGlitch(${bias})`);
        clearTimeout(mech.glitchTimeout);
        const glitch = mech.getRandomInteger() < bias ? 'snow' : 'dark',
            isSnow = (glitch === 'snow'),
            glitchSound = mech[glitch + 'Sound'];

        function stopGlitch() {
            //console.log('stopGlitch()');
            document.body.classList.remove('glitch-' + glitch);
            if (isSnow) {
                elem.snow.style.opacity = 0;
                elem.snow.style.transition = 'opacity 80ms';
            } else {
                elem.elec.style.visibility = 'hidden';
            }
            glitchSound && glitchSound.pause();

            // Chance of an echo glitch a short time after the last one
            if (mech.getRandomInteger() < 5) {
                mech.isGlitch && setTimeout(startGlitch, mech.getRandomInteger(50, 450));
            } else {
                mech.isGlitch && mech.setGlitch(isSnow ? 7 : 3);
            }
        }

        function startGlitch() {
            //console.log('startGlitch()');
            const duration = mech.getRandomInteger(50, 450);
            document.body.classList.add('glitch-' + glitch);
            if (isSnow) {
                const opacity = mech.getRandomInteger(duration < 150 ? 20 : 90, 100) / 100;
                elem.snow.style.transition = 'none';
                elem.snow.style.opacity = opacity;
            } else {
                elem.elec.style.visibility = 'visible';
            }
            glitchSound && glitchSound.play();
            setTimeout(stopGlitch, duration);
        }

        mech.glitchTimeout = setTimeout(startGlitch, mech.getRandomInteger(50, 20000));
    },


    changeButtonLabels(labels) {
        //console.log('mech.changeButtonLabels()');
        for (let i = 0; i < 4; i++) {
            elem.buttons.bottom[i].id = labels[i] ? labels[i].toLowerCase() : '';
            elem.buttons.bottom[i].innerHTML = labels[i] || '';
        }
    },


    setContent(category, isRoll, callback) {
        console.log(`mech.setContent(${category}, ${isRoll})`);
        if (isRoll) {
            elem.content.style.width = `calc(48% + ${mech.scrollbarWidth}px)`;
            elem.content.innerHTML = `<div id="roll">${content[category]}</div>`;
            elem.content.scrollTop = 0;
        } else {
            elem.content.style.width = '48%';
            elem.content.innerHTML = content[category];
        }
        typeof callback === 'function' && callback();
    },


    changeContent(button) {
        //console.log('mech.changeContent()');
        const colorTransition = 'color 80ms',
              buttonId = button.id || button.innerHTML.toLowerCase(),
              category = buttonId.replace(/\s/g, '_'),
              isInterest = ~['science', 'language', 'psychology', 'wilderness'].indexOf(category);
              console.log(category);
        mech.isRoll = !!~mech.rollingContent.indexOf(category);
        elem.buttons.last.style.transition = colorTransition;
        elem.buttons.left.style.transition = colorTransition;
        elem.buttons.right.style.transition = colorTransition;
        elem.snow.style.transition = 'opacity 80ms';
        elem.dark.style.transition = 'opacity 80ms';
        elem.buttons.last.style.color = null;
        elem.buttons.left.classList.add('inactive');
        mech.isRoll || elem.buttons.right.classList.add('inactive');
        elem.snow.style.opacity = 1;
        elem.dark.style.opacity = 0.2;
        mech.snowSound && mech.snowSound.paused && mech.snowSound.play();
        elem.gears.top.style.transform = 'rotate(360deg)';
        elem.gears.bottom.style.transform = 'rotate(-360deg)';

        setTimeout(() => {
            if (mech.isRoll) {
                elem.gears.top.style.transition = 'transform 0s';
                elem.gears.bottom.style.transition = 'transform 0s';
            }
            elem.gears.top.style.transform = 'rotate(0)';
            elem.gears.bottom.style.transform = 'rotate(0)';

            setTimeout(() => {
                elem.gears.top.style.transition = `transform 1.6s linear`;
                elem.gears.bottom.style.transition = `transform 2.4s linear`;
            }, mech.isRoll ? 100 : 2700);
        }, 2700);

        setTimeout(() => {
            const colorTransition = 'color 240ms';
            elem.buttons.last = button;
            button.style.transition = colorTransition;
            elem.snow.style.transition = 'opacity 240ms';
            elem.dark.style.transition = 'opacity 240ms';
            elem.content.className = category;

            const isRoll = (mech.isRoll || isInterest),
                callback = () => {
                    elem.buttons.left.style.transition = colorTransition;
                    elem.buttons.right.style.transition = colorTransition;
                    elem.buttons.right.classList.remove('inactive');

                    setTimeout(function calloutRightButton(i = 0) {
                        mech.toggleGlowRing();
                        (i < 7) && setTimeout(() => {
                            calloutRightButton(++i);
                        }, 250);
                    }, 2700);
                    roll.initialize();
                };
            mech.setContent(category, isRoll, mech.isRoll ? callback : null);
            button.style.color = '#4b7a44';
            location.hash = category;
            elem.snow.style.opacity = 0;
            elem.dark.style.opacity = 1;

            setTimeout(() => {
                if (mech.snowSound) {
                    mech.snowSound.paused || mech.snowSound.pause();
                    mech.snowSound.currentTime = 0;
                }
            }, 200);
        }, 300);
    },


    resizeMech() {
        //console.log('mech.resizeMech()');
        if (window.innerWidth >= mech.originalWidth && window.innerHeight >= mech.originalHeight) {
            elem.wrapper.style.width = mech.originalWidth + 'px';
            elem.wrapper.style.height = mech.originalHeight + 'px';
        } else if (window.innerWidth < window.innerHeight) {
            elem.wrapper.style.width = window.innerWidth + 'px';
            elem.wrapper.style.height = (window.innerWidth / mech.originalWidth) * mech.originalHeight + 'px';
        } else {
            elem.wrapper.style.height = window.innerHeight + 'px';
            elem.wrapper.style.width = (window.innerHeight / mech.originalHeight) * mech.originalWidth + 'px';
        }
    },


    resizeContentWidth() {
        //console.log('mech.resizeContentWidth()');
        if (document.getElementById('roll')) {
            elem.content.style.width = `calc(48% + ${mech.scrollbarWidth}px)`;  // 47.2%
        } else {
            elem.content.style.width = '48%';
        }
    },


    resizeFont() {
        //console.log('mech.resizeFont()');
        document.body.style.fontSize = parseFloat(elem.wrapper.style.width) / 35 + 'px';
    },


    resize() {
        //console.log('mech.resize()');
        mech.resizeMech();
        mech.resizeContentWidth();
        mech.resizeFont();
    },


    setScrollButtons() {
        //console.log('mech.setScrollButtons()');
        if (roll.index === 0) {
            elem.buttons.left.classList.add('inactive');
        } else {
            elem.buttons.left.classList.remove('inactive');
        }
        if (roll.index === roll.headings.length - 1) {
            elem.buttons.right.classList.add('inactive');
        } else {
            elem.buttons.right.classList.remove('inactive');
        }
    },


    toggleGlowRing() {
        //console.log('mech.toggleGlowRing()');
        if (mech.isGlowRing) {
            elem.buttons.right.classList.remove('glow-ring');
        } else {
            elem.buttons.right.classList.add('glow-ring');
        }
        mech.isGlowRing = !mech.isGlowRing;
    },


    getQuery(variable) {
        //console.log(`mech.getQuery(${variable})`);
        const query = window.location.search.substring(1),
            variables = query.split('&');
        let pair, value = false;

        for (let i = 0; i < variables.length; i++) {
            pair = variables[i].split('=');
            if (pair[0] === variable) {
                value = pair[1];
            }
        }
        return value;
    },


    getLocationHash() {
        //console.log('mech.getLocationHash()');
        const hash = location.hash.substr(1).toLowerCase(),
            isValid = content && content[hash];

        if (!isValid && hash) {
            let state = mech.isBtnClicked;
            mech.isBtnClicked = true;
            location.hash = '';
            mech.isBtnClicked = state;
        }
        return isValid ? hash : 'contact';
    },


    initialize() {
        //console.log('mech.initialize()');
        mech.snowSound = mech.loadSound('snow');
        mech.darkSound = mech.loadSound('dark');
        mech.gearSound = mech.loadSound('gear');
        mech.mode = mech.getQuery('mode') || 'auto';
        mech.resize();

        const category = mech.getLocationHash(),
            getBottomLabels = () => {
                for (let labels in mech.buttonLabels) {
                    for (let i = 0; i < mech.buttonLabels[labels].length; i++) {
                        if (mech.buttonLabels[labels][i].toLowerCase() === category) {
                            return mech.buttonLabels[labels];
                        }
                    }
                }
            },
            bottomLabels = mech.buttonLabels[category] || getBottomLabels(),
            isRoll = (mech.rollingContent.indexOf(category) === -1 && ['servers', 'science', 'language', 'psychology', 'wilderness'].indexOf(category) === -1) ? false : true;

        elem.buttons.last.style.color = null;
        mech.isRoll = !!~mech.rollingContent.indexOf(category);
        elem.content.className = category;
        mech.setContent(category, isRoll);

        if (mech.isRoll) {
            roll.initialize();
            mech.setScrollButtons();
        }
        bottomLabels && mech.changeButtonLabels(bottomLabels);

        const button = document.getElementById(category);
        if (button) {
            elem.buttons.last = button;
            button.style.color = '#4b7a44';
        }
        elem.snow.style.transition = 'opacity 240ms';
        elem.dark.style.transition = 'opacity 240ms';
        mech.isBtnClicked = false;
    },


    initListeners() {
        //console.log('mech.initListeners()');
        elem.glitch.addEventListener('click', event => {
            mech.isGlitch = !mech.isGlitch;
            if (mech.isGlitch) {
                mech.setGlitch();
                elem.glitch.innerHTML = 'Stop Glitching';
            } else {
                clearTimeout(mech.glitchTimeout);
                elem.glitch.innerHTML = 'Allow Glitching';
            }
        });

        elem.content.addEventListener('optimizedScroll', () => {
            elem.gears.left.style.transform = `rotate(${elem.content.scrollTop / 2}deg)`;
        });

        elem.key.addEventListener('click', event => {
            //console.info('Event: click on key');
            //const ROLL = document.getElementById('roll');
            event.stopPropagation();
            roll.scrollDown(roll.headings.length - 1);
        });

        elem.buttons.left.addEventListener('click', event => {
            //console.info('Event: click on button-left');
            event.stopPropagation();
            if (roll.div) {
                roll.setPosition();
                roll.setIndex();
                if (roll.index !== 0) {
                    roll.index--;
                }
                mech.setScrollButtons();
                roll.scrollUp(roll.index);
            }
        });

        elem.buttons.right.addEventListener('click', event => {
            //console.info('Event: click on button-right');
            event.stopPropagation();
            if (roll.div) {
                roll.setPosition();
                roll.setIndex();
                if (roll.index !== roll.headings.length - 1) {
                    roll.index++;
                }
                mech.setScrollButtons();
                roll.scrollDown(roll.index);
            }
        });

        for (let i = 0; i < elem.buttons.top.length; i++) {
            elem.buttons.top[i].addEventListener('click', function (event) {
                //console.info(`Event: click on ${event.target.id}`);
                const bottomLabels = mech.buttonLabels[this.id];
                event.stopPropagation();
                mech.isBtnClicked = true;
                bottomLabels && mech.changeButtonLabels(bottomLabels);
                mech.changeContent(this);
            }, false);
        }

        for (let i = 0; i < elem.buttons.bottom.length; i++) {
            elem.buttons.bottom[i].addEventListener('click', function (event) {
                //console.info(`Event: click on ${event.target.id}`);
                event.stopPropagation();
                mech.isBtnClicked = true;
                mech.changeContent(this);
            }, false);
        }
    }

};
